import React from "react";

export const NO_CONNECTION_ERROR_MESSAGE =
  "Vi fikk ikke kontakt med Internett. Sjekk om tilkoblingen er i orden, og prøv igjen.";
export const UNAUTHORIZED_ERROR_MESSAGE =
  "Vi har dessverre problemer med å logge deg inn. ";

export const NO_CONTACT_INFO_FOR_CUSTOMER_WARNING_MESSAGE = (
  <>
    Vi har dessverre ikke nok informasjon registrert på dette medlemsnummeret
    til å kunne gå videre. Sjekk at du har skrevet inn riktig medlemsnummer,
    eller ta kontakt med kundesenteret via{" "}
    <a
      href="https://naf.no/kontakt-oss"
      target="_blank"
      rel="noopener noreferrer"
    >
      vårt skjema
    </a>
    , eller ring 08 505.
  </>
); //ok

export const CUSTOMER_ID_NOT_FOUND_ERROR_MESSAGE =
  "Vi fant dessverre ikke et medlemskap knyttet til dette nummeret. Sjekk om du har en skrivefeil og prøv på nytt."; //ok

export const DEFAULT_ERROR_MESSAGE = "Oida. Noe gikk visst galt.";

export const VERIFICATION_CODE_DOES_NOT_MATCH_ERROR =
  "Koden er feil. Prøv igjen."; //ok
export const VERIFICATION_PHONE_DOES_NOT_MATCH_ERROR =
  "Det skjedde noe galt da vi prøvde å verifisere koden din."; // Skal ikke skje egentlig
export const VERIFICATION_EMAIL_DOES_NOT_MATCH_ERROR =
  "Det skjedde noe galt da vi prøvde å verifisere koden din."; // Skal ikke skje egentlig

export const NO_ACTIVE_LINKING_SESSION_ERROR =
  "Vi har rusk i maskineriet akkurat nå, og kunne ikke bekrefte koden du sendte oss. Vennligst spør om å få en ny kode tilsendt.";

export const VERIFICATION_CODE_ALREADY_CONFIRMED_ERROR =
  "Denne koden er allerede bekreftet.";

  export const ATTEMPT_TO_LINK_WITH_SELF_ERROR =
  "Medlemsnummeret du forsøker å koble med er allerede koblet til denne e-postadressen.";

export const CONTACT_INFO_PROVIDED_IS_NOT_VALID_ERROR =
  "Det du skrev stemmer ikke med det vi har registrert. Prøv igjen. Tror du vi har en skrivefeil? Ta kontakt med vårt kundesenter."; //ok

export const createCodeGuessLimitReachedError = (data) => {
  const { maxAttempts } = data.lockOutError || {};
  return `Du har skrevet feil kode ${maxAttempts} ganger. Gå tilbake og spør om å få en ny kode tilsendt for å prøve igjen. `;
};

export const createCodeCreationLockoutError = (data) => {
  return `Beklager! Du har prøvd for mange ganger, du må vente litt før du prøver igjen.`;
};

export const VERIFICATION_CODE_EXPIRED_ERROR =
  "Koden er utgått. Spør om å få en ny kode for å prøve igjen."; //ok
