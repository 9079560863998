import React from "react";

const WarningMessage = ({ children }) => {
  if (!children) return null;
  return (
    <div
      id="warning-message"
      className="alert alert-warning"
      style={{ display: "block" }}
    >
      {children}
    </div>
  );
};

export default WarningMessage;