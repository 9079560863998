import React, { useState, useEffect } from "react";
import { RadioGroup, Radio, FormControlLabel } from "@material-ui/core";

import NafIdTextInput from "../../shared/inputs/NafIdTextInput";

const CODE_VERIFY_OPTION_TYPES = Object.freeze({
  EMAIL: 0,
  PHONE: 1,
});

const createOptions = ({
  maskedContactInfo: {
    email: emailMasked,
    phone: phoneMasked,
    address: addressMasked,
    householdOwnerAddress: householdOwnerAddressMasked,
    householdOwnerPhone: householdOwnerPhoneMasked,
    householdOwnerEmail: householdOwnerEmailMasked,
  },
  isHouseholdMember,
}) => {
  const opts = {};
  if (emailMasked) {
    opts["email"] = {
      value: emailMasked,
      type: CODE_VERIFY_OPTION_TYPES.EMAIL,
      placeholder: "E-postadresse",
      detail:
        `Medlemskapet ditt er registert på e-postadressen ${emailMasked}. Oppgi den fullstendige e-postadressen for å motta verifiseringskoden dit.`,
    };
  }
  if (phoneMasked) {
    opts["phone"] = {
      value: phoneMasked,
      type: CODE_VERIFY_OPTION_TYPES.PHONE,
      placeholder: "Mobilnummer",
      detail:
        `Medlemskapet ditt er registert på mobilnummeret ${phoneMasked}. Oppgi det fullstendige mobilnummeret for å motta verifiseringskoden dit.`,
    };
  }

  if (isHouseholdMember && householdOwnerEmailMasked) {
    opts["householdOwnerEmail"] = {
      value: householdOwnerEmailMasked,
      type: CODE_VERIFY_OPTION_TYPES.EMAIL,
      placeholder: "E-postadresse",
      detail:
        `Oppgi e-epostadressen hovedmedlemmet i husstanden er registrert med (${householdOwnerEmailMasked}) for å motta verifiseringskoden til den e-postadressen.`,
    };
  }
  if (isHouseholdMember && householdOwnerPhoneMasked) {
    opts["householdOwnerPhone"] = {
      value: householdOwnerPhoneMasked,
      type: CODE_VERIFY_OPTION_TYPES.PHONE,
      placeholder: "Mobilnummer",
      detail:
        `Oppgi mobilnummeret hovedmedlemmet i husstanden er registrert med (${householdOwnerPhoneMasked}) for å motta verifiseringskoden til det mobilnummeret.`,
    };
  }
  return opts;
};

const EnterContactInfoField = ({
  placeholder,
  enteredContactInfo,
  setEnteredContactInfo,
  inputTypeId,
  detail,
}) => {
  let type;
  switch (inputTypeId) {
    case CODE_VERIFY_OPTION_TYPES.EMAIL: {
      type = "email";
      break;
    }
    case CODE_VERIFY_OPTION_TYPES.PHONE: {
      type = "tel";
      break;
    }
    default: {
      type = "string";
    }
  }

  return (
    <div className="form-group">
      <NafIdTextInput
        autoFocus
        value={enteredContactInfo}
        setValue={setEnteredContactInfo}
        placeholder={placeholder}
        type={type}
        id="confirm-info-input"
        maxLength={type === "tel" ? 8: ""}
      />
      <div style={{ paddingTop: ".25em" }}>{detail}</div>
    </div>
  );
};

const SendCodeOptions = ({ onChange, onSubmit, ...availableCodeOptions }) => {
  const [selectedOption, setSelectedOption] = useState();
  const [enteredContactInfo, setEnteredContactInfo] = useState("");
  const selectableCodeOptions = createOptions(availableCodeOptions);

  useEffect(() => {
    if (selectedOption) {
      onChange({
        [selectedOption]: enteredContactInfo,
      });
    }
  }, [enteredContactInfo, selectedOption, onChange]);

  return (
    <div id="select-contact-method-form" style={{ display: "block" }}>
      <form
        onSubmit={(event) => {
          event.preventDefault();

          if (onSubmit) onSubmit(event);
        }}
      >
        <label style={{ marginBottom: "20px" }}>For å kunne bekrefte din identitet må vi sende deg en verifiseringskode.</label>
        <label>Jeg ønsker å motta koden på:</label>
        <RadioGroup
          name="customized-radios"
          onChange={(evt) => {
            const newOpt = evt.target.value;
            if (newOpt !== selectedOption) {
              setSelectedOption(evt.target.value);
              setEnteredContactInfo("");
            }
          }}
        >
          {Object.keys(selectableCodeOptions).map((key) => {
            const opt = selectableCodeOptions[key];
            const prefix =
              opt.type === CODE_VERIFY_OPTION_TYPES.EMAIL ? "E-post" : "SMS";
            return (
              <div key={key}>
                <FormControlLabel
                  value={key}
                  control={<Radio color="primary" />}
                  label={`${prefix}: ${opt.value}`}
                />
                {key === selectedOption ? (
                  <div style={{ padding: "0 0 20px 0" }}>
                    <EnterContactInfoField
                      inputTypeId={opt.type}
                      detail={selectableCodeOptions[selectedOption].detail}
                      placeholder={
                        selectableCodeOptions[selectedOption].placeholder
                      }
                      {...{ enteredContactInfo, setEnteredContactInfo }}
                    />
                  </div>
                ) : null}
              </div>
            );
          })}
        </RadioGroup>
      </form>
    </div>
  );
};
export default SendCodeOptions;
