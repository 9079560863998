import React, { useRef, useEffect, useState } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import MembershipNumberPromptPage from "./pages/MembershipNumberPromptPage";
import ConfirmIdentityPage from "./pages/ConfirmIdentityPage";
import CallForVerificationCodePage from "./pages/CallForVerificationCodePage";
import LinkingSuccessfulPage from "./pages/LinkingSuccessfulPage";

function trackPageView(location) {
  window.dataLayer.push({
    event: "virtualPageView",
    page: location.pathname,
    pageTitle: window.document.title,
    pageUrl: window.location.href,
  });
}

export const ROOT_PATHS = {
  LINK_CRM: "/koble-medlemskap",
};

export const LINK_CRM_PATHS = {
  CUSTOMER_ID_PROMPT: `${ROOT_PATHS.LINK_CRM}/`,
  SUCCESS: `${ROOT_PATHS.LINK_CRM}/suksess`,
  VERIFY: `${ROOT_PATHS.LINK_CRM}/skriv-verifikasjonskode`,
  CONFIRM_IDENTITY: `${ROOT_PATHS.LINK_CRM}/hent-medlemskap`,
};

const routes = [
  {
    path: LINK_CRM_PATHS.CONFIRM_IDENTITY,
    Page: ConfirmIdentityPage,
  },
  {
    path: LINK_CRM_PATHS.VERIFY,
    Page: CallForVerificationCodePage,
  },
  {
    path: LINK_CRM_PATHS.SUCCESS,
    Page: LinkingSuccessfulPage,
  },
  { path: LINK_CRM_PATHS.CUSTOMER_ID_PROMPT, Page: MembershipNumberPromptPage },
];

export const TrackPageView = withRouter(({ history, children, location }) => {
  const [currentLocation, setCurrentLocation] = useState(null);

  useEffect(() => {
    setCurrentLocation(location);
  }, [location]);

  useEffect(() => {
    if (currentLocation) {
      const timeout = setTimeout(trackPageView, 100, currentLocation);

      return () => clearTimeout(timeout);
    }
  }, [currentLocation]);

  const timeout = useRef();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(setCurrentLocation, null, location);
    });

    return unlisten;
  }, [history]);

  return children;
});

const NavigationRouter = () => {
  return (
    <Switch>
      {routes.map(({ path, Page }) => (
        <Route path={path} key={path}>
          <Page />
        </Route>
      ))}
    </Switch>
  );
};

export default NavigationRouter;
