import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useContext
} from "react";
import jwtDecode from "jwt-decode";
import createAuth0Client from "@auth0/auth0-spa-js";
import ApiClient from "./ApiClient";
import { ApplicationState } from "../shared/ApplicationState";
import LoadingScreen from "../shared/layouts/LoadingScreen";

export const Auth0Context = createContext({});

const options =
  window.location.host === "profil.id.naf.no"
    ? {
        domain: "id.naf.no",
        client_id: "tvPWMH3TXzS2Ukoj1uz46CAfup86uRc5"
      }
    : {
        domain: "stage.id.naf.no",
        client_id: "LYWUQ2fFz6U6yNJOGkssOZd7IAGo1EOL"
      };

const initOptions = {
  ...options,
  redirect_uri: window.location.origin,
  onRedirectCallback: () => window.history.replaceState(null, null, "/")
};

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0ContextProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK
}) => {
  const [jwt, setJwt] = useState();
  const [auth0Client, setAuth0] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const {
    state: globalApplicationState,
    restoreState: restoreGlobalApplicationState
  } = useContext(ApplicationState);
  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        ...initOptions
      });
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        if (appState) {
          restoreGlobalApplicationState(appState);
        }
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (!isAuthenticated) {
        const currentAppState = globalApplicationState.current;
        auth0FromHook.loginWithRedirect({
          appState: currentAppState
        });
      }
      setAuth0(auth0FromHook);
    };
    initAuth0();
    // eslint-disable-next-line
  }, [globalApplicationState, restoreGlobalApplicationState]);

  const client = useMemo(() => {
    if (!jwt) return null;
    return new ApiClient(jwt);
  }, [jwt]);

  useEffect(() => {
    const effect = async () => {
      if (isAuthenticated && auth0Client) {
        const claims = await auth0Client.getIdTokenClaims();
        const id_token = claims.__raw;
        if (id_token) {
          setJwt(id_token);
        }
      }
    };
    effect();
  }, [setJwt, isAuthenticated, auth0Client]);

  const emailClaimed = useMemo(() => {
    if (!jwt) return null;
    const { email } = jwtDecode(jwt);
    return email;
  }, [jwt]);

  return (
    <Auth0Context.Provider value={{ client, emailClaimed }}>
      {client ? children : <LoadingScreen />}
    </Auth0Context.Provider>
  );
};
