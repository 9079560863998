import React from "react";
import "./layoutStyles.css";
import PageHeader from "./PageHeader";

const ScreenLayout = ({ children, title, detail }) => {
  return (
    <div className="login-container row justify-content-center align-items-center">
      <div className="login-box" id="login-box" style={{
        display: 'block',
        maxWidth: '375px',
      }} >

      <PageHeader title={title} detail={detail} />
      <div id="login-form" style={{display: 'block'}}>{children}</div>
      </div>
    </div>
  );
};

export default ScreenLayout;
/*
"display: block; max-width: 375px;"*/