import React, { useEffect, useCallback, useRef } from "react";

const INPUT_BOX_SIDE_MARGIN_PERCENTAGES = 4;

const OneTimeCode = ({ codeLength, label, code, onChange, disabled }) => {
  const nextInputFieldRef = useRef();

  useEffect(() => {
    if (nextInputFieldRef && nextInputFieldRef.current) {
      nextInputFieldRef.current.focus();
    }
  }, [code, nextInputFieldRef]);

  const onChangePart = useCallback(
    evt => {
      const newValue = evt.target.value;
      if (newValue.length > 2) {
        const codeSubstr = newValue.substr(0, codeLength + 1);
        onChange(codeSubstr);
      } else if (newValue.length === 2) {
        onChange(current => current.concat(newValue.substr(1, 2)));
      } else if (newValue.length === 1) {
        onChange(current => current.concat(newValue));
      } else {
        onChange(current => {
          const newLastIdx = current.length - 1;
          if (newLastIdx < 0) return "";
          return current.substr(0, newLastIdx);
        });
      }
    },
    [onChange, codeLength]
  );

  const InputPart = ({ idx }) => {
    const id = `code${idx}`;
    const value = code.length > idx ? code[idx] : "";

    const isActiveField = code.length === Math.max(0, idx + 1);
    return (
      <input
        style={{
          textAlign: "center",
          width: "100%",
          boxSizing: "border-box",
          borderRadius: "7px",
          borderStyle: "solid",
          borderWidth: "1px",
          height: "100%",
          borderColor: "rgb(190, 197, 197)"
        }}
        ref={isActiveField ? nextInputFieldRef : null}
        value={value}
        id={id}
        type="tel"
        inputMode="numeric"
        name="otc"
        autoComplete="one-time-code"
        onChange={onChangePart}
        autoCorrect="off"
        pattern="[0-9]*"
        disabled={disabled}
        autoFocus={idx === 0}
        onFocus={e => {
          const tmp = e.target.value;
          e.target.value = "";
          e.target.value = tmp;
        }}
      />
    );
  };

  return (
    <div>
      {label ? <label>{label}</label> : null}
      <div
        className="form-group"
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "0 10px 0 10px"
        }}
      >
        {new Array(codeLength).fill(0).map((_, idx) => (
          <div
            key={`code-digit-${idx}`}
            style={{
              flex: 1,
              width: `${100 / codeLength - INPUT_BOX_SIDE_MARGIN_PERCENTAGES}%`,
              height: "50px",
              padding: `0 ${INPUT_BOX_SIDE_MARGIN_PERCENTAGES /
                2}%  0 ${INPUT_BOX_SIDE_MARGIN_PERCENTAGES / 2}%`
            }}
          >
            <InputPart id={`otc-input${idx}`} idx={idx} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OneTimeCode;
