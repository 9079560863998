import React from "react";

const NafIdTextInput = ({ setValue, value, ...props }) => {
  return (
    <input
      type="string"
      className="form-control"
      variant="outlined"
      value={value}
      onChange={evt => {
        setValue(evt.target.value);
      }}
      {...props}
    />
  );
};
export default NafIdTextInput;
