import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ScreenLayout from "./../shared/layouts/ScreenLayout";
import MembershipNumberInputField from "./../shared/inputs/MembershipNumberInputField";
import PrimaryButton from "../shared/buttons/PrimaryButton";
import { MergeRequestContext } from "../api/MergeRequestService";
import ErrorMessage from "../shared/text/ErrorMessage";
import { Auth0Context } from "../api/Auth0Context";
import { LINK_CRM_PATHS } from "../Navigation";

const MembershipNumberPromptPage = () => {
  const navigation = useHistory();
  const { verifyValidMembershipId, customerId } = useContext(
    MergeRequestContext
  );
  const [membershipNumber, setMembershipNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setMembershipNumber(customerId);
  }, [customerId]);

  async function onVerify() {
    setIsLoading(true);
    try {
      await verifyValidMembershipId(membershipNumber);
      setErrorMessage(null);
      navigation.push(LINK_CRM_PATHS.CONFIRM_IDENTITY);
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  const { emailClaimed } = useContext(Auth0Context);
  const disabled = !(membershipNumber && membershipNumber.length > 0);

  return (
    <>
      <ScreenLayout title="Koble medlemskap til din e-postadresse">
        <form
          onSubmit={event => {
            event.preventDefault();
            if (!disabled && !isLoading) {
              onVerify();
            }
          }}
        >
          <p>
            Du er logget inn med e-postadressen <strong>{emailClaimed}</strong>. Skriv inn
            medlemsnummeret du ønsker å koble til denne e-postadressen.
            <br />
            <br />
          </p>
          <ErrorMessage>{errorMessage}</ErrorMessage>
          <MembershipNumberInputField
            membershipNumber={membershipNumber}
            setMembershipNumber={setMembershipNumber}
          />
          <PrimaryButton
            title="Neste"
            disabled={disabled}
            onClick={() => onVerify()}
            isLoading={isLoading}
          />
        </form>
        <p>
          Medlemsnummeret finner du på medlemskortet ditt, e-poster fra oss,
          eller fakturaer.
        </p>
      </ScreenLayout>
    </>
  );
};

export default MembershipNumberPromptPage;
