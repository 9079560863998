import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import { Auth0Context } from "./Auth0Context";
import { LINK_CRM_PATHS } from "../Navigation";

import {
  DEFAULT_ERROR_MESSAGE,
  CUSTOMER_ID_NOT_FOUND_ERROR_MESSAGE,
} from "./ApiErrorStrings";
import LoadingScreen from "../shared/layouts/LoadingScreen";

export const MergeRequestContext = createContext({});

export const MergeRequestContextProvider = ({ children }) => {
  const [verificationMethodsPreview, setVerificationMethodsPreview] = useState(
    null
  );
  const [customerIdToClaim, setCustomerIdToClaim] = useState(null);
  const [contactInfoUsed, setContactInfoUsed] = useState({});
  const [verificationCodeResponse, setVerificationCodeResponse] = useState();

  const { client } = useContext(Auth0Context);
  const navigation = useHistory();

  const confirmVerificationCode = useCallback(
    async (code) => {
      const response = await client.post("/LinkCrm/Confirm", {
        ...verificationCodeResponse,
        customerId: customerIdToClaim,
        code,
      });
      if (response.ok) {
        return response.data;
      } else {
        throw new Error(DEFAULT_ERROR_MESSAGE);
      }
    },
    [client, verificationCodeResponse, customerIdToClaim]
  );

  const requestVerificationCode = useCallback(
    async (requestBody) => {
      setContactInfoUsed(requestBody);
      const response = await client.post(
        "/LinkCrm/SendConfirmationCode",
        requestBody
      );
      if (response.ok) {
        setVerificationCodeResponse(response.data);
        return response.data;
      }
      throw new Error(DEFAULT_ERROR_MESSAGE);
    },
    [client, setVerificationCodeResponse]
  );

  const verifyValidMembershipId = useCallback(
    async (customerId) => {
      const response = await client.get("/LinkCrm/LookupCustomer", {
        customerId,
      });
      if (response.ok) {
        setCustomerIdToClaim(customerId);
        setVerificationMethodsPreview(response.data);
        return;
      } else if (response.status === 404) {
        throw new Error(CUSTOMER_ID_NOT_FOUND_ERROR_MESSAGE);
      }

      throw new Error(DEFAULT_ERROR_MESSAGE);
    },
    [client, setCustomerIdToClaim, setVerificationMethodsPreview]
  );

  const [isRestoring, setIsRestoring] = useState(true);

  const restoreLinkingSession = useCallback(async () => {
    const response = await client.get("/LinkCrm/RestoreSession");
    if (response.ok) {
      const { customerId, codeLength, expiration, contactInfo } = response.data;
      await verifyValidMembershipId(customerId);
      setVerificationCodeResponse({ contactInfo, codeLength, expiration });
      setContactInfoUsed({
        customerId,
        contactInfo,
      });
    }
    return response;
  }, [client, setVerificationCodeResponse, verifyValidMembershipId]);

  useEffect(() => {
    const restore = async () => {
      if (client) {
        try {
          setIsRestoring(true);
          const response = await restoreLinkingSession();
          if (response.ok) {
            navigation.push(LINK_CRM_PATHS.VERIFY);
          } else {
            navigation.push(LINK_CRM_PATHS.CUSTOMER_ID_PROMPT);
          }
        } catch (err) {
          navigation.push(LINK_CRM_PATHS.CUSTOMER_ID_PROMPT);
        } finally {
          setIsRestoring(false);
        }
      }
    };
    restore();
  }, [client, restoreLinkingSession, navigation]);

  return (
    <MergeRequestContext.Provider
      value={{
        verifyValidMembershipId,
        customerId: customerIdToClaim,
        verificationPreview: verificationMethodsPreview,
        requestVerificationCode,
        contactInfoUsedForVerification: contactInfoUsed,
        verificationCodeResponse,
        confirmVerificationCode,
        isRestoring,
      }}
    >
      {isRestoring ? <LoadingScreen /> : children}
    </MergeRequestContext.Provider>
  );
};
