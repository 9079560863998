import React from "react";
import { Link } from "react-router-dom";
import { LINK_CRM_PATHS } from "../../Navigation";

const CustomerIdPreview = ({ customerId }) => {
  return (
    <div>
      <label>Ditt medlemsnummer</label>
      <div className="selected-data-preview-form">
        <p className="centered-text">{customerId}</p>
        <Link to={LINK_CRM_PATHS.CUSTOMER_ID_PROMPT}>Endre</Link>
      </div>
    </div>
  );
};

export default CustomerIdPreview;
