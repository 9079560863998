import React, { useContext } from "react";
import { ApplicationState } from "../shared/ApplicationState";
import ScreenLayout from "./../shared/layouts/ScreenLayout";

import { Auth0Context } from "../api/Auth0Context";
import PrimaryButton from "../shared/buttons/PrimaryButton";
import { MergeRequestContext } from "../api/MergeRequestService";

const LinkingSuccessfulPage = () => {
  const { emailClaimed } = useContext(Auth0Context);
  const { state: globalApplicationState } = useContext(ApplicationState);
  const { customerId } = useContext(MergeRequestContext);
  const returnURL = globalApplicationState.current.onSuccessReturnUrl;
  return (
    <ScreenLayout title="Medlemskapet er koblet!" detail="">
      <p>
        E-postadressen på medlemskapet {customerId} er nå endret til{" "}
        <b>{emailClaimed}</b>
      </p>
      {returnURL ? (
        <PrimaryButton
          title="Avslutt"
          onClick={() => {
            if (returnURL) {
              window.location = returnURL;
            }
          }}
        />
      ) : (
        <p>Du kan nå lukke dette nettleservinduet.</p>
      )}
    </ScreenLayout>
  );
};

export default LinkingSuccessfulPage;
