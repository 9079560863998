import React from "react";
import CenteredSpinner from "../layouts/CenteredSpinner";
import { Link } from "react-router-dom";

export const PrimaryButtonLink = ({ to, title, ...props }) => {
  return (
    <Link
      {...props}
      role="button"
      to={to}
      className={["btn", "btn-primary", "btn-block"].filter(c => c).join(" ")}
    >
      {title}
    </Link>
  );
};

const PrimaryButton = ({ title, isLoading, onClick, disabled, ...props }) => {
  return (
    <a
      {...props}
      role="button"
      className={[
        "btn",
        "btn-primary",
        "btn-block",
        disabled ? "disabled" : null
      ]
        .filter(c => c)
        .join(" ")}
      onClick={!disabled && !isLoading ? onClick : null}
      disabled={disabled}
    >
      {isLoading ? <CenteredSpinner /> : title}
    </a>
  );
};

export default PrimaryButton;
