import React, { createContext, useCallback, useEffect, useRef } from "react";
export const ApplicationState = createContext({});

const RETURN_PARAMETER_NAME = "onSuccessReturnUrl";

export const ApplicationStateProvider = ({ children }) => {
  const state = useRef({});
  const setState = useCallback(
    newState => {
      state.current = newState;
    },
    [state]
  );

  const restoreState = useCallback(
    state => {
      setState(state);
    },
    [setState]
  );
  useEffect(() => {
    const params = window.location.search;
    if (params.includes(`${RETURN_PARAMETER_NAME}=`)) {
      const parameters = params.replace("?", "").split("&");
      const param = parameters.find(p => p.includes(RETURN_PARAMETER_NAME));
      const value = param ? param.substr(param.indexOf("=") + 1) : null;
      if (value) {
        setState({
          ...state.current,
          [RETURN_PARAMETER_NAME]: decodeURIComponent(value)
        });
      }
    }
  }, [setState, state]);

  return (
    <ApplicationState.Provider value={{ restoreState, state: state }}>
      {children}
    </ApplicationState.Provider>
  );
};
