import React, {
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { useHistory, Link } from "react-router-dom";
import ScreenLayout from "./../shared/layouts/ScreenLayout";
import { MergeRequestContext } from "../api/MergeRequestService";
import ErrorMessage from "../shared/text/ErrorMessage";

import CenteredSpinner from "../shared/layouts/CenteredSpinner";

import OneTimeCode from "../shared/inputs/OneTimeCode";

import { LINK_CRM_PATHS } from "./../Navigation";
import LinkButton from "../shared/buttons/LinkButton";

const CallForVerificationCodePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigation = useHistory();
  const {
    contactInfoUsedForVerification,
    requestVerificationCode,
    verificationCodeResponse,
    confirmVerificationCode,
  } = useContext(MergeRequestContext);

  const confirmationCodeLength = useMemo(() => {
    if (!verificationCodeResponse || !verificationCodeResponse.codeLength) {
      return 0;
    }
    return verificationCodeResponse.codeLength;
  }, [verificationCodeResponse]);

  const onSendCodePressed = useCallback(async () => {
    setIsLoading(true);
    if (
      !contactInfoUsedForVerification ||
      Object.keys(contactInfoUsedForVerification).length === 0
    ) {
      navigation.push(LINK_CRM_PATHS.CONFIRM_IDENTITY);
    }
    try {
      await requestVerificationCode(contactInfoUsedForVerification);
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [
    contactInfoUsedForVerification,
    requestVerificationCode,
    setIsLoading,
    navigation,
  ]);

  const [enteredCode, setEnteredCode] = useState("");

  const onCodeChanged = useCallback(
    async (code) => {
      if (
        confirmationCodeLength > 0 &&
        code &&
        code.length === confirmationCodeLength
      ) {
        setIsLoading(true);
        try {
          const responseData = await confirmVerificationCode(code);
          // All ok.
          setErrorMessage(null);
          navigation.push(LINK_CRM_PATHS.SUCCESS);

          return responseData;
        } catch (err) {
          setEnteredCode("");
          setErrorMessage(err.message);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      confirmVerificationCode,
      confirmationCodeLength,
      setErrorMessage,
      navigation,
      setIsLoading,
    ]
  );

  useEffect(() => {
    if (onCodeChanged) onCodeChanged(enteredCode);
  }, [onCodeChanged, enteredCode]);

  const phone = verificationCodeResponse.contactInfo
    ? verificationCodeResponse.contactInfo.phone ||
      verificationCodeResponse.contactInfo.householdOwnerPhone
    : null;

  const email = verificationCodeResponse.contactInfo
    ? verificationCodeResponse.contactInfo.email ||
      verificationCodeResponse.contactInfo.householdOwnerEmail
    : null;

  const recipient = phone || email;

  return (
    <ScreenLayout title="Skriv inn verifikasjonskoden">
      <div>
        <p>
          Vi har sendt en {confirmationCodeLength}-sifret kode til{" "}
  <b>{recipient}</b>. Du kan lukke dette vinduet og komme tilbake når
          den har kommet.
        </p>
      </div>

      <form
        style={{ marginBottom: "1em" }}
        onSubmit={(event) => event.preventDefault()}
      >
        <OneTimeCode
          codeLength={confirmationCodeLength}
          code={enteredCode}
          onChange={setEnteredCode}
          disabled={isLoading}
        />
        <div style={{ height: "2em;", marginBottom: "1em" }}>
          {isLoading ? <CenteredSpinner /> : null}
        </div>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </form>

      <div>
        <h2 style={{ fontWeight: "bold", fontSize: "1.4em" }}>
          Fikk du ikke koden?
        </h2>
        <div>
          <p>
            {email ? "Sjekk søppelposten eller" : "Vent litt eller"} {" "}
            <LinkButton onClick={onSendCodePressed}>send på nytt</LinkButton>.
          </p>
        </div>

        <div>
          <p>
            Du kan også{" "}
            <Link to={LINK_CRM_PATHS.CONFIRM_IDENTITY}>
              bytte hvor du får koden tilsendt
            </Link>
            .
          </p>
        </div>
      </div>
    </ScreenLayout>
  );
};

export default CallForVerificationCodePage;
