import React from "react";

const ErrorMessage = ({ children }) => {
  if (!children) return null;
  return (
    <div
      id="error-message"
      className="alert alert-danger"
      style={{ display: "block" }}
    >
      {children}
    </div>
  );
};



export default ErrorMessage;
