import React from "react";
import "./membershipNumberInputField.css";

import NafIdTextInput from "./NafIdTextInput";

const MembershipNumberInputField = ({
  membershipNumber,
  setMembershipNumber
}) => {
  const updateMembershipNumberIfValid = value => {
    if (/^\d+$/.test(value) || !value) {
      setMembershipNumber(value);
    }
  };

  return (
    <div className="form-group">
      <label>Ditt medlemsnummer:</label>
      <NafIdTextInput
        value={membershipNumber || ""}
        pattern="[0-9]*"
        setValue={updateMembershipNumberIfValid}
        placeholder="Medlemsnummer"
        autoFocus
      />
    </div>
  );
};

export default MembershipNumberInputField;
