import React from "react";
import ScreenLayout from "./ScreenLayout";
import CenteredSpinner from "./CenteredSpinner";

const LoadingScreen = () => (
  <ScreenLayout title="Laster">
    <div>
      <CenteredSpinner />
    </div>
  </ScreenLayout>
);

export default LoadingScreen;
