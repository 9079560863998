import React, { useState, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import ScreenLayout from "./../shared/layouts/ScreenLayout";
import { MergeRequestContext } from "../api/MergeRequestService";

import CustomerIdPreview from "../shared/buttons/MembershipNumberPreview";
import SendCodeOptions from "./components/SendCodeOptions";
import PrimaryButton from "../shared/buttons/PrimaryButton";
import { verifyInputField } from "./../shared/utils/verifyFields";
import ErrorMessage from "../shared/text/ErrorMessage";
import WarningMessage from "../shared/text/WarningMessage";

import { NO_CONTACT_INFO_FOR_CUSTOMER_WARNING_MESSAGE } from "../api/ApiErrorStrings";
import { LINK_CRM_PATHS } from "../Navigation";
import LoadingScreen from "../shared/layouts/LoadingScreen";

function isCustomerPotentiallyIdentifiable(previewData) {
  const {
    maskedContactInfo: {
      email: emailMasked,
      phone: phoneMasked,
      householdOwnerPhone: householdOwnerPhoneMasked,
      householdOwnerEmail: householdOwnerEmailMasked,
    },
  } = previewData;

  return (
    emailMasked ||
    phoneMasked ||
    householdOwnerEmailMasked ||
    householdOwnerPhoneMasked
  );
}

const ConfirmIdentityPage = () => {
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigation = useHistory();
  const {
    customerId,
    verificationPreview,
    requestVerificationCode,
    isRestoring,
  } = useContext(MergeRequestContext);

  const [contactInfo, setContactInfo] = useState({});

  const navigateToNextPage = useCallback(() => {
    navigation.push(LINK_CRM_PATHS.VERIFY);
  }, [navigation]);

  const onSendCodePressed = useCallback(async () => {
    setIsAwaitingResponse(true);
    try {
      await requestVerificationCode({
        customerId,
        contactInfo,
      });
      navigateToNextPage();
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsAwaitingResponse(false);
    }
  }, [
    contactInfo,
    requestVerificationCode,
    navigateToNextPage,
    setIsAwaitingResponse,
    customerId,
    setErrorMessage,
  ]);

  if (isRestoring || !verificationPreview) return <LoadingScreen />;

  const canCustomerBeIdentified = isCustomerPotentiallyIdentifiable(
    verificationPreview
  );

  if (canCustomerBeIdentified) {
    return (
      <ScreenLayout title="Bekreft din identitet">
        <CustomerIdPreview customerId={customerId} />
        <SendCodeOptions
          onChange={setContactInfo}
          onSubmit={onSendCodePressed}
          {...verificationPreview}
        />
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <div>
          <PrimaryButton
            disabled={!verifyInputField(contactInfo)}
            title="Send meg verifikasjonskode"
            isLoading={isAwaitingResponse}
            onClick={onSendCodePressed}
          />
        </div>
        <p>
          <strong>Står du fast?</strong>
          <br />
          Sjekk at du har skrevet inn riktig medlemsnummer, eller ta kontakt med
          kundesenteret via{" "}
          <a
            href="https://naf.no/kontakt-oss"
            target="_blank"
            rel="noopener noreferrer"
          >
            vårt skjema
          </a>
          , eller ring 08{"\u00a0"}505.
        </p>
      </ScreenLayout>
    );
  }

  return (
    <ScreenLayout title="Bekreft din identitet">
      <ErrorMessage>{errorMessage}</ErrorMessage>
      <CustomerIdPreview customerId={customerId} />
      <br />
      <WarningMessage>
        {NO_CONTACT_INFO_FOR_CUSTOMER_WARNING_MESSAGE}
      </WarningMessage>
    </ScreenLayout>
  );
};

export default ConfirmIdentityPage;
