import React from "react";
import "./layoutStyles.css";

const PageHeader = ({ title, detail }) => {
  return (
    <div className="login-header">
      <h1 id="login-title">{title}</h1>
      {detail ? <p>{detail}</p> : null}
      <hr />
    </div>
  );
};

export default PageHeader;
