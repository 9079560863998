import React from "react";
import { MergeRequestContextProvider } from "./api/MergeRequestService";
import { Auth0ContextProvider } from "./api/Auth0Context";
import { ApplicationStateProvider } from "./shared/ApplicationState";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import NavigationRouter, { TrackPageView, ROOT_PATHS } from "./Navigation";

const redirectMap = {
  'profil.id.naf.no': 'https://koble.id.naf.no',
  'development.profil.id.naf.no': 'https://dev-koble.id.naf.no',
  'stage.profil.id.naf.no': 'https://test-koble.id.naf.no',
  'default': 'https://dev-koble.id.naf.no'
}

function App() {
  const redirectLocation = redirectMap[window.location.hostname] ?? redirectMap.default;
  window.location = redirectLocation;

  return;
  // return (
  //   <div>
  //     <div className="page-container d-flex flex-column">
  //       <div className="naf-logo-background">
  //         <img
  //           id="naf-logo"
  //           src="https://res.cloudinary.com/nafmedier/image/upload/q_auto:best/f_svg/v1/Logo/logo"
  //           alt="NAF logo"
  //         />
  //       </div>
  //       <Router>
  //         <ApplicationStateProvider>
  //           <Auth0ContextProvider>
  //             <TrackPageView>
  //               <Switch>
  //                 <Route path={ROOT_PATHS.LINK_CRM}>
  //                   <MergeRequestContextProvider>
  //                     <NavigationRouter />
  //                   </MergeRequestContextProvider>
  //                 </Route>
  //                 <Route path="/">
  //                   <Redirect to={ROOT_PATHS.LINK_CRM} />
  //                 </Route>
  //               </Switch>
  //             </TrackPageView>
  //           </Auth0ContextProvider>
  //         </ApplicationStateProvider>
  //       </Router>
  //     </div>
  //   </div>
  // );
}

export default App;
