export function verifyEmail(email) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
}

export function verifyPhone(number) {
  return number.length === 8;
}

export function verifyInputField(data) {
  if (!data || Object.keys(data).length === 0) return false;
  return Object.keys(data)
    .map(key => {
      const value = data[key];
      if (["phone", "householdOwnerPhone"].indexOf(key) > -1) {
        return verifyPhone(value);
      }

      if (["email", "householdOwnerEmail"].indexOf(key) > -1) {
        return verifyEmail(value);
      }
      return false;
    })
    .every(x => x);
}
